import { ImageAsset } from "@/components/ImageAsset";
import { popboardViewState } from "@/context/states";
import { responsiveImage } from "@/lib/helper";
import theme from "@/styles/theme";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useRecoilState } from "recoil";

export const ContentContainer = ({
  children,
  headline,
  subheadline,
  bandImage,
  image = null,
  backgroundColor = "#fbfbfb",
  variant = "default",
}) => {
  const [popboardView, setPopboardView] = useRecoilState(popboardViewState);

  return (
    <>
      {variant !== "fullscreen" && !popboardView && (
        <Container maxWidth={false} disableGutters>
          <Container
            maxWidth={false}
            sx={{
              height: 200,
              [theme.breakpoints.up("sm")]: {
                height: 540,
              },
              position: "relative",
              zIndex: "-50",
            }}
          >
            {image ? (
              <ImageAsset url={image} priority={true} layout="fill" />
            ) : (
              <ImageAsset
                url="/img/page-hero-music.jpg"
                local={true}
                priority={true}
                layout="fill"
              />
            )}
          </Container>

          <Container maxWidth="xl">
            <Grid
              container
              justifyContent="center"
              sx={{
                position: "relative",
                mt: -20,
                mb: 3,
                [theme.breakpoints.up("sm")]: {
                  mt: -57,
                  mb: 14,
                },
                "&::before": {
                  content: "''",
                  background: "url(/img/line-05-orange.svg)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top",
                  mr: "3rem",
                  mt: "2.5rem",
                  width: "45%",
                  height: "3rem",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  zIndex: "0",
                  [theme.breakpoints.up("sm")]: {
                    mr: "6rem",
                    mt: "6rem",
                    width: "50%",
                    height: "5rem",
                  },
                  [theme.breakpoints.up("md")]: {
                    mr: "6rem",
                    mt: "4.5rem",
                    width: "45%",
                    height: "7rem",
                  },
                  [theme.breakpoints.up("lg")]: {
                    mr: "12rem",
                    mt: "4rem",
                    width: "35%",
                    height: "8rem",
                  },
                },
              }}
            >
              <Grid
                item
                xs={12}
                md={10}
                sx={{
                  position: "relative",
                  zIndex: "50",
                  textAlign: "center",
                  mb: bandImage ? 4 : 0,
                  [theme.breakpoints.up("sm")]: {
                    mb: bandImage ? 11 : 0,
                  },
                }}
              >
                <Typography variant="h1">{headline}</Typography>
                {subheadline && (
                  <Typography
                    variant="subtitle"
                    sx={{
                      fontSize: "1rem",
                      color: theme.palette.common.white,
                      [theme.breakpoints.up("sm")]: {
                        fontSize: "1.875rem",
                      },
                    }}
                  >
                    {subheadline}
                  </Typography>
                )}
              </Grid>

              {bandImage && (
                <Grid item xs={10}>
                  <ImageAsset
                    url={responsiveImage(bandImage, 1263)}
                    width={1263}
                    height={710}
                    placeholder="blur"
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  mt: 4,
                  pt: variant === "shared-map" ? 0 : 3,
                  pb: 3,
                  backgroundColor: backgroundColor,
                  [theme.breakpoints.up("sm")]: {
                    mt: 10,
                    paddingY: 13,
                  },
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  sx={{
                    "& h4.MuiTypography-root, h5.MuiTypography-root, h6.MuiTypography-root":
                      {
                        color: theme.palette.common.black,
                      },
                    "& h5.MuiTypography-root": {
                      fontSize: "1.25rem",
                    },
                  }}
                >
                  <Grid item xs={variant === "shared-map" ? 12 : 10} sm={10}>
                    {children}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
      {variant === "fullscreen" && !popboardView && (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            height: "100%",
          }}
        >
          {children}
        </Container>
      )}
      {popboardView && (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            padding: "1rem",
            [theme.breakpoints.up("sm")]: {
              padding: "2rem",
            },
            backgroundColor: theme.palette.common.white,
            "& .MuiTypography-root": {
              color: theme.palette.common.black,
            },
          }}
        >
          {children}
        </Container>
      )}
    </>
  );
};

ContentContainer.propTypes = {
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  image: PropTypes.string,
};
