import { ContentContainer } from "@/components/ContentContainer";
import { Button, Grid, Typography } from "@mui/material";
import Link from "next/link";

export default function FourOhFour() {
  return (
    <ContentContainer headline="404" subheadline="Page not found">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8} lg={6} textAlign="center">
          <Typography
            variant="h3"
            component="p"
            textAlign="center"
            color="common.black"
            sx={{mb: 3}}
          >
            Hoppla. Die von dir aufgerufene Seite ist uns leider nicht bekannt
          </Typography>
          <Button component={Link} href="/" variant="contained" color="secondary">
            Zur Startseite
          </Button>
        </Grid>
      </Grid>
    </ContentContainer>
  );
}
